import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import cn from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import MuiLink from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Head from './Head';
import FirstScreen from './FirstScreen';
import Footer from './Footer';

const useStyles = makeStyles(theme => ({
  header: {
    position: 'absolute',
    width: '100%',
    top: 0,
    left: 0,
  },
  header__inner: {
    display: 'flex',
    padding: '4px 0',
    [theme.breakpoints.up('sm')]: {
      padding: '12px 0',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '24px 0',
    },
  },
  header__languages: {
    display: 'flex',
    alignItems: 'baseline',
    marginLeft: 'auto'
  },
  header__languagesItem: {
    display: 'block',
    textTransform: 'uppercase',
    textDecoration: 'none',
    color: 'inherit',
    cursor: 'pointer',
    padding: 6,
  },
  header__languagesItem_active: {
    color: '#B88437',
  },
  nav: {
    position: 'absolute',
    bottom: 24,
    left: 0,
    right: 0,
    [theme.breakpoints.up('lg')]: {
      bottom: 36,
    },
  },
  nav__list: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  nav__item: {
    position: 'relative',
    cursor: 'pointer',
    textTransform: 'uppercase',
  },
  _line: {
    '&:before': {
      content: '""',
      position: 'absolute',
      bottom: '150%',
      height: 36,
      width: 2,
      backgroundColor: '#000',
    },
    '&:hover:before': {
      bottom: '120%',
    },
  },
  _aboutLink: {
    '&:before': {
      left: 0,
    }
  },
  _contactsLink: {
    '&:before': {
      right: 0,
    }
  },
  section: {
    padding: '60px 0',
    [theme.breakpoints.up('md')]: {
      padding: '80px 0',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '100px 0',
    },
    [theme.breakpoints.up('xl')]: {
      padding: '140px 0',
    },
  },
  section__inner: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  section__title: {
    textTransform: 'uppercase',
    maxWidth: 480,
    marginBottom: 32,
    [theme.breakpoints.up('md')]: {
      maxWidth: 260,
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: 360,
    },
  },
  section__content: {
    flexGrow: 1,
  },
}));

const text = {
  nav: {
    contacts: {
      en: 'Contacts',
      ru: 'Контакты',
    },
    about: {
      en: 'About',
      ru: 'О нас',
    }
  },
  about: {
    title: {
      en: 'We are management and marketing agency',
      ru: 'Мы эксперты в большом спорте',
    },
    mainText: {
      en: [
        'Golden Toys LLP is a UK-based international company specializing in sports marketing and management.',
        'We represent the interests of players, coaches and clubs.',
      ],
      ru: [
        'Golden Toys LLP, международная компания основанная в Великобритании. Мы специализируемся на рынке спорта и шоу - бизнеса.',
        'Для работы на футбольном рынке у нашей компании есть все необходимые лицензии, в том числе от футбольной ассоциации Англии (FA) (license no. IMS003860) и официальная лицензия РФС (lN4, категория PRO).',
        'Мы представляем интересы игроков и тренеров.'
      ],
    },
  },
  contacts: {
    title: {
      en: 'Do you have a project in mind?',
      ru: 'Остались вопросы?'
    },
  }
}

export default () => {
  const classes = useStyles();
  const { location } = useHistory();
  const [, langPathPart] = location.pathname.split('/');
  const lang = langPathPart || 'en';
  const renderHead = () => (
    <Head
      title="Golden Toys"
      description="Sport marketing agency" />
  );

  const handleClick = (id) => () => {
    const positionTop = document.getElementById(id).offsetTop;
    window.scrollTo({
      top: positionTop,
      behavior: 'smooth'
    });
  };

  return (
    <React.Fragment>
      {renderHead()}
      {/* <header className={classes.header}>
        <Container maxWidth={false}>
          <div className={classes.header__inner}>
            <div className={classes.header__languages}>
              <Link 
                to='/'
                className={cn(classes.header__languagesItem, {
                  [classes.header__languagesItem_active]: lang === 'en',
                })}
              >
                <Typography variant="body1">en</Typography>
              </Link>
              <span>/</span>
              <Link
                to='/ru'
                className={cn(classes.header__languagesItem, {
                  [classes.header__languagesItem_active]: lang === 'ru',
                })}
              >
                <Typography variant="body1">ru</Typography>
              </Link>
            </div>
          </div>
        </Container>
      </header> */}
      <nav className={classes.nav}>
        <Container maxWidth={false}>
          <div className={classes.nav__list}>
            <div
              onClick={handleClick('about')}
              className={cn(classes.nav__item, classes._line, classes._aboutLink)}
            >
              <Typography variant="body1">{text.nav.about[lang]}</Typography>
            </div>
            <div
              onClick={handleClick('contacts')}
              className={cn(classes.nav__item, classes._line, classes._contactsLink)}
            >
              <Typography variant="body1">{text.nav.contacts[lang]}</Typography>
            </div>
          </div>
        </Container>
      </nav>
      <FirstScreen />
      <section id="about" className={classes.section}>
        <Container maxWidth={false}>
          <div className={classes.section__inner}>
            <div className={classes.section__title}>
              <Typography variant="h1" component="h2">{text.about.title[lang]}</Typography>
            </div>
            <div className={classes.section__content}>
              <Container maxWidth="sm" disableGutters>
                {text.about.mainText[lang].map((paragraph) => (
                  <Typography key={paragraph} variant="body1" paragraph>
                    {paragraph}
                  </Typography>
                ))}
              </Container>
            </div>
          </div>
        </Container>
      </section>
      <section id="contacts" className={classes.section}>
        <Container maxWidth={false}>
          <div className={classes.section__inner}>
            <div className={classes.section__title}>
              <Typography variant="h1" component="h2">{text.contacts.title[lang]}</Typography>
            </div>
            <div className={classes.section__content}>
              <Container maxWidth="sm" disableGutters>
                <MuiLink color="inherit" href="mailto:info@goldentoys.uk">
                  <Typography variant="h4" align="center">
                    info@goldentoys.uk
                  </Typography>
                </MuiLink>
              </Container>
            </div>
          </div>
        </Container>
      </section>
      <Footer />
    </React.Fragment>
  );
};
