import React from 'react';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  footer: {
    padding: '24px 0',
  },
  footer__inner: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'center',
      flexDirection: 'column',
    },
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'space-between',
    },
  },
}));

const year = new Date().getFullYear();

export default () => {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Container maxWidth={false}>
        <div className={classes.footer__inner}>
          <div>
            <Typography variant="body1">© {year}. All rights reserved.</Typography>
          </div>
          <div>
            <Typography variant="body1">Designed by <Link color="inherit" href="mailto:iamafanasev@gmail.com">
                Oleg Afanasev
              </Link>
            </Typography>
          </div>
        </div>
      </Container>
    </footer>
  );
};
