import { createMuiTheme } from '@material-ui/core/styles';
import pink from '@material-ui/core/colors/pink';

const theme = createMuiTheme();

export default createMuiTheme({
  overrides: {
    MuiContainer: {
      root: {
        [theme.breakpoints.up('lg')]: {
          paddingLeft: 32,
        },
        [theme.breakpoints.up('xl')]: {
          paddingLeft: 48,
        },
      },
    },
  },
  palette: {
    primary: {
      light: '#757ce8',
      main: '#4d05e8',
      dark: '#4d13d1',
      contrastText: '#fff',
    },
    secondary: pink,
  },
  typography: {
    h1: {
      fontSize: 36,
      fontWeight: 500,
      [theme.breakpoints.up('md')]: {
        fontSize: 42,
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: 56,
      },
      [theme.breakpoints.up('xl')]: {
        fontSize: 64,
      },
    },
    body1: {
      [theme.breakpoints.up('xl')]: {
        fontSize: 20,
      },
    },
  },
  breakpoints: {
    keys: [
      "xs",
      "sm",
      "md",
      "lg",
      "xl",
    ],
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1600,
    }
  }
});