import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Home from '../components/Home';
import './App.css';

const App = () => {
  return (
    <Switch>
      <Route path="/" component={Home} />
    </Switch>
  );
};

export default App;
