import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import cn from 'classnames';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles(theme => ({
  firstScreen__inner: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    minHeight: '90vh',
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 100,
    },
    [theme.breakpoints.up('md')]: {
      minHeight: '100vh',
      flexDirection: 'row',
      justifyContent: 'space-around',
    },
  },
  firstScreen__content: {
    alignSelf: 'center',
  },
  _right: {
    userSelect: 'none',
    pointerEvents: 'none',
    [theme.breakpoints.down('sm')]: {
      marginTop: 32,
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: 24,
    },
    [theme.breakpoints.up('lg')]: {
      marginLeft: 48,
    },
  },
  _left: {
    userSelect: 'none',
    pointerEvents: 'none',
    [theme.breakpoints.down('sm')]: {
      marginBottom: 32,
    },
    [theme.breakpoints.up('sm')]: {
      marginRight: 24,
    },
    [theme.breakpoints.up('lg')]: {
      marginRight: 48,
    },
  },
  firstScreen__contentImg: {
    height: 42,
    [theme.breakpoints.up('lg')]: {
      height: '4vw',
    },
  },
  firstScreen__imageWrapper: {
    position: 'relative',
    width: '0%',
    alignSelf: 'center',
    boxSizing: 'border-box',
    transition: '1.5s',
    [theme.breakpoints.up('md')]: {
      padding: '0 32px',
    },
  },
  _width: {
    width: '90%',
    [theme.breakpoints.up('sm')]: {
      width: '66%',
    },
    [theme.breakpoints.up('md')]: {
      width: '44%',
    },
    [theme.breakpoints.up('lg')]: {
      width: '40%',
    },
  },
  firstScreen__background: {
    width: '100%',
    paddingTop: '119%',
    opacity: 0,
    transform: 'scale(0.9)',
    transition: '0.3s',
    backgroundImage: 'url(/abstract-figure.png)',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
  },
  attributeImage: {
    display: 'block',
    userSelect: 'none',
    pointerEvents: 'none',
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '50%',
    transform: 'translate(-50%, -50%)',
    [theme.breakpoints.up('sm')]: {
      width: '36%',
    },
  },
  _hockeyStick: {
    width: '66%',
  },
  _goldenShoes: {
    width: '60%',
  },
  _opacity: {
    opacity: 1,
  },
  _scale: {
    transform: 'scale(1)',
  },
}));

const extraClassesByImageUrls = {
  '/golden-hockey-helmet.png': '_helmet',
  '/golden-ball.png': '_goldenBall',
  '/golden-hockey-helmet.png': '_helmet',
  '/award.png': '_award',
  '/golden-helmet.png': '_goldenHelmet',
  '/icon-logo.svg': '_iconLogo',
  '/hockey-stick.png': '_hockeyStick',
  '/golden-football-shoes.png': '_goldenShoes',
  '/golden-puck.png': '_goldenPuck',
};

const imageUrls = Object.keys(extraClassesByImageUrls);

export default () => {
  const classes = useStyles();
  const [showBg, setShowBg] = React.useState(false);
  const [showImageWrapper, setShowImageWrapper] = React.useState(false);
  const [imgIndex, setImgIndex] = React.useState(null);

  React.useEffect(() => {
    let timerShowImageWrapper;
    let timerShowBg;
    let timerShowAttrs;
    let timerShowAttr;
    const tick = () => {
      setImgIndex(oldIndex => (oldIndex >= imageUrls.length - 1 ? 0 : oldIndex + 1));
    }

    timerShowAttrs = setTimeout(() => {
      timerShowAttr = setInterval(tick, 400);
    }, 1500);
    timerShowImageWrapper = setTimeout(() => {
      setShowImageWrapper(true)
    }, 0);

    timerShowBg = setTimeout(() => {
      setShowBg(true)
    }, 1500);

    return () => {
      clearInterval(timerShowAttr);
      clearTimeout(timerShowAttrs);
      clearTimeout(timerShowImageWrapper);
      clearTimeout(timerShowBg);
    };
  }, []);

  const imgUrl = imgIndex ? imageUrls[imgIndex] : null;

  return (
    <div className={classes.firstScreen}>
      <Container maxWidth={false}>
        <div className={classes.firstScreen__inner}>
          <div className={cn(classes.firstScreen__content, classes._left)}>
            <img className={classes.firstScreen__contentImg} src="/golden.svg" />
          </div>
          <div className={cn(classes.firstScreen__imageWrapper, {
            [classes._width]: showImageWrapper,
          })}>
            <div className={cn(classes.firstScreen__background, {
              [classes._opacity]: showBg,
              [classes._scale]: showBg,
            })}></div>
            {imgUrl ? (
              <img
                src={imgUrl}
                className={cn(classes.attributeImage, classes[extraClassesByImageUrls[imgUrl]])}
              />
            ) : null}
          </div>
          <div className={cn(classes.firstScreen__content, classes._right)}>
            <img className={classes.firstScreen__contentImg} src="/toys.svg" />
          </div>
        </div>
      </Container>
    </div>
  );
};
