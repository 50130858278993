import React from 'react';
import Helmet from "react-helmet";

export default (props) => (
  <Helmet
    title={props.title}
    titleTemplate="%s | goldentoys.uk"
    meta={[
        {"name": "description", "content": props.description},
        {"property": "og:description", "content": props.description},
        {"property": "og:type", "content": "article"},
        {"property": "og:image", "content": "/icon-logo.jpg"},
        {"property": "og:image:secure_url", "content": "https://goldentoys.uk/icon-logo.jpg"},
        {"property": "og:image:width", "content": "600"},
        {"property": "og:image:height", "content": "600"},
    ]} />
);
